import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Services from './pages/services';
import Footer from './components/Footer';
import About from './pages/about';
import { useEffect, useRef, useState } from 'react';
import ScrollToTop from './components/ScrollToTop';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-HM8P5TSXLQ');

function App() {
  const contactRef = useRef(null);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  return (
    <div className="App">

      <BrowserRouter>
        <ScrollToTop>
          <Header state={state} toggleDrawer={toggleDrawer} contactRef={contactRef}></Header>
          <Routes>
            <Route path='/' element={<Home contactRef={contactRef}></Home>}></Route>
            <Route path='/services' element={<Services></Services>}></Route>
            <Route path='/about' element={<About></About>}></Route>
          </Routes>
          <Footer contactRef={contactRef}></Footer>
        </ScrollToTop>
      </BrowserRouter>

    </div>

  );
}

export default App;
