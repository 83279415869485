
import { ArrowDownwardOutlined } from '@mui/icons-material'
import React, { useRef, useEffect } from 'react'
import dropper from '../images/eye-dropper.svg'
import computer from '../images/computer.svg'
import interactive from '../images/interactive.svg'
import network from '../images/network-cloud.svg'
import mobile from '../images/mobile-button.svg'
import ReactGA from 'react-ga4';
const Services = () => {
    const webRef = useRef(null);
    const mobileRef = useRef(null);
    const desktopRef = useRef(null);
    const uiRef = useRef(null);
    const graphicRef = useRef(null);
    const scroll = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const boldFont = {
        fontFamily: 'RNS',
        fontWeight: 600
    }
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    }, [])
    return (
        <>
            <div className='services flex flex-col justify-between'>
                <div className='w-full p-4 py-8 md:py-10 lg:py-16 px-4 lg:px-12'>
                    {/* <p className='uppercase text-gray-400 text-center text-lg' style={boldFont}>Our services</p> */}
                    <h1 className='uppercase text-center text-primary text-2xl lg:text-4xl'>We offer software <br></br> for your business</h1>
                    <div className='my-16'></div>
                    <div className='flex flex-col'>
                        <h2 onClick={() => scroll(webRef)} className='uppercase text-gray-500 lg:text-xl text-lg leading-9 cursor-pointer' style={boldFont}>Website Development</h2>
                        <h2 onClick={() => scroll(mobileRef)} className='uppercase text-gray-500 lg:text-xl text-lg leading-9 cursor-pointer' style={boldFont}>Mobile App Development</h2>
                        <h2 onClick={() => scroll(desktopRef)} className='uppercase text-gray-500 lg:text-xl text-lg leading-9 cursor-pointer' style={boldFont}> desktop app Development</h2>
                        <h2 onClick={() => scroll(uiRef)} className='uppercase text-gray-500 lg:text-xl text-lg leading-9 cursor-pointer' style={boldFont}>User interface design</h2>
                        <h2 onClick={() => scroll(graphicRef)} className='uppercase text-gray-500 lg:text-xl text-lg leading-9 cursor-pointer' style={boldFont}>Graphic Design</h2>
                    </div>
                    <div className='my-16'></div>
                    <div>
                        <ArrowDownwardOutlined fontSize='large' className='text-secondary'></ArrowDownwardOutlined>
                    </div>

                </div>
                <div className='bg-secondary w-full py-6 md:py-12 px-4 md:px-10 lg:py-16 lg:px-16 sm:mt-8 lg:mt-4  text-center'>
                    <h1 className='uppercase text-primary text-xl md:text-2xl xl:text-3xl'> Software is the magic thing whose importance only goes up over time</h1>


                </div>
            </div>
            {/* <div className='my-12'></div> */}
            <h2 className='sm:px-4 lg:px-12 py-12 bg-secondary uppercase text-white decoration-primary underline underline-offset-2 text-md lg:text-xl px-4 md:px-10'>Are you an NGO? Get 15% on all of your projects!</h2>
            <div className='pt-10 pb-20 sm:px-4 lg:px-12 bg-secondary'>

                <div className='px-4 md:px-10' ref={webRef}>
                    <div className='flex flex-col items-center w-full' >
                        {/* <h4 className='uppercase text-[#AAAAAA] text-lg'>{'</>'}</h4> */}
                        <img src={network} className="w-[36px]"></img>
                        <h1 className='uppercase text-xl lg:text-2xl text-primary mt-1'>Website Development</h1>
                        <p className='xl:container my-2 text-center text-white'>
                            Start building your website today and make it as beautiful as you want it to be. We can do anything from designing your site, coding it, hosting it, or even managing it. Linebits will give you a website designed with best practices in mind,
                            and will come with full lifetime support from us to maintain it.
                        </p>
                        <div className='my-4'></div>
                        <div className='w-full grid md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-2 lg:gap-6'>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary  flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Blog & News</h3>
                            </div>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Portfolio</h3>
                            </div>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>E-commerce</h3>
                            </div>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Content Management System</h3>
                            </div>
                        </div>


                    </div>
                    <div className='my-20'>
                        <div className='bg-secondary w-full py-6 md:py-12 px-4 md:px-10 lg:py-16 lg:px-16 sm:mt-8 lg:mt-4 text-center'>
                            <h1 className='uppercase text-primary text-xl md:text-2xl xl:text-3xl'> The digital world is our playground</h1>
                        </div>
                    </div>
                    <div className='flex flex-col items-center w-full ' ref={mobileRef}>
                        {/* <h4 className='uppercase text-[#AAAAAA] text-lg'>{'</>'}</h4> */}
                        <img src={mobile} className="w-[36px]"></img>
                        <h1 className='uppercase text-xl lg:text-2xl text-primary mt-1'>Mobile App Development</h1>
                        <p className='xl:container my-2 text-center text-white'>Linebits provides solutions for all shapes and sizes.
                            Create anything from a single view app all the way up to multi-screen apps (for both Android and iOS).
                            Get an app developed without wasting time on back-and-forth or settling for an out-of-date template.
                        </p>
                        <div className='my-4'></div>
                        <div className='w-full grid md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-2 lg:gap-6'>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary  flex flex-col justify-center w-full lg:w-auto '>
                                <h3 className='uppercase font-extrabold'>Blog & News</h3>
                            </div>

                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>E-commerce</h3>
                            </div>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Management App</h3>
                            </div>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Progressive Web App</h3>
                            </div>
                        </div>

                    </div>

                    <div className='my-20'>
                        <div className='bg-secondary w-full py-6 md:py-12 px-4 md:px-10 lg:py-16 lg:px-16 sm:mt-8 lg:mt-4  text-center'>
                            <h1 className='uppercase text-primary text-xl md:text-2xl xl:text-3xl'> Be a Linebit</h1>
                        </div>
                    </div>
                    <div className='flex flex-col items-center' ref={desktopRef}>
                        {/* <h4 className='uppercase text-[#AAAAAA] text-lg'>{'</>'}</h4> */}
                        <img src={computer} className="w-[36px]"></img>
                        <h1 className='uppercase text-xl lg:text-2xl text-primary mt-1'>Desktop app Development</h1>
                        <p className='xl:container my-2 text-center text-white'>
                            We strive to provide innovative solutions that cater to your business needs.
                            Our focus is on quality, usability, privacy and security. But most of all, we're an awesome team that loves making high quality desktop software for you!
                        </p>
                        <div className='my-4'></div>
                        <div className='w-full grid md:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-2 lg:gap-6'>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary  flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Windows App</h3>
                            </div>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Macos App</h3>
                            </div>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Cross Platform App</h3>
                            </div>

                        </div>

                    </div>

                    <div className='my-20'>
                        <div className='bg-secondary w-full py-6 md:py-12 px-4 md:px-10 lg:py-16 lg:px-16 sm:mt-8 lg:mt-4  text-center'>
                            <h1 className='uppercase text-primary text-xl md:text-2xl xl:text-3xl'> The right software can take care of mundane tasks so you can focus on what's most important: growing your business </h1>
                        </div>
                    </div>
                    <div className='flex flex-col items-center' ref={uiRef}>
                        {/* <h4 className='uppercase text-[#AAAAAA] text-lg'>{'</>'}</h4> */}
                        <img src={interactive} className="w-[36px]"></img>
                        <h1 className='uppercase text-xl lg:text-2xl text-primary mt-1'>User Interface Design</h1>
                        <p className='xl:container my-2 text-center text-white'>
                            Our designers work painstakingly on every pixel to make sure your site is at its best. We don't want any surprises when it's time to launch your site.

                        </p>
                        <div className='my-4'></div>
                        <div className='w-full grid md:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-2 lg:gap-6'>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary  flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Web & mobile interface</h3>
                            </div>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center w-full lg:w-auto' >
                                <h3 className='uppercase font-extrabold'>USer Experience Design</h3>
                            </div>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Prototyping</h3>
                            </div>
                            {/* <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center'>
                                <h3 className='uppercase font-extrabold'>Content Management System</h3>
                            </div> */}
                        </div>

                    </div>

                    <div className='my-20'>
                        <div className='bg-secondary w-full py-6 md:py-12 px-4 md:px-10 lg:py-16 lg:px-16 sm:mt-8 lg:mt-4  text-center'>
                            <h1 className='uppercase text-primary text-xl md:text-2xl xl:text-3xl'>You can’t run your business if you don’t have the software it needs!</h1>
                        </div>
                    </div>
                    <div className='flex flex-col items-center' ref={graphicRef}>
                        {/* <h4 className='uppercase text-[#AAAAAA] text-lg'>{'</>'}</h4> */}
                        <img src={dropper} className="w-[36px]"></img>
                        <h1 className='uppercase text-xl lg:text-2xl text-primary mt-1'>Graphic Design</h1>
                        <p className='xl:container my-2 text-center text-white'>
                            We translate your vision into digital designs while exploring the latest creative trends and platforms. Our core values are logical approach and client satisfaction. Our promise - your brand will be uniquely one-of-a-kind.

                        </p>
                        <div className='my-4'></div>
                        <div className='w-full grid md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-2 lg:gap-6'>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Branding</h3>
                            </div>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary  flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Logo Design</h3>
                            </div>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Business Card Design</h3>
                            </div>
                            <div className='p-3 text-center rounded-sm bg-[#f8f8f8] text-primary flex flex-col justify-center w-full lg:w-auto'>
                                <h3 className='uppercase font-extrabold'>Social Media Templates</h3>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Services