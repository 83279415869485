import { Close, MenuOutlined } from '@mui/icons-material'
import { Drawer, Menu } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/linebits-logo.svg'
import logoDark from '../images/linebits-logo-dark.svg'
const Header = ({ contactRef, toggleDrawer, state }) => {
    const scroll = () => contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    return (
        <>
            <div className='w-full flex-row bg-[#f8f8f8] justify-between items-center px-4 py-5 md:py-5 shadow-sm'>
                <div className='xl:container w-full mx-auto flex justify-between items-center'>
                    <div className='logo'>
                        <Link to={'/'}>
                            <img src={logo} className="w-[100px]"></img>
                        </Link>
                    </div>
                    <div className=' flex-row hidden md:flex justify-between items-center'>
                        <Link to='/'>
                            <h5 className='uppercase mr-10 cursor-pointer text-secondary hover:text-primary transition duration-200 ease-in-out text-sm'>home</h5>
                        </Link>
                        <Link to='/services'>
                            <h5 className='uppercase mr-10 cursor-pointer text-secondary hover:text-primary transition duration-200 ease-in-out text-sm'>our services</h5>
                        </Link>
                        {/* <Link to='/offers'>
                        <h5 className='uppercase mr-10 cursor-pointer hover:text-primary transition duration-200 ease-in-out text-sm'>our offers</h5>
                    </Link> */}
                        <Link to='/about'>
                            <h5 className='uppercase mr-10 cursor-pointer hover:text-primary transition duration-200 ease-in-out text-sm text-secondary'>about us</h5>
                        </Link>
                        {/* <p className='uppercase mr-10 cursor-pointer hover:text-primary transition duration-200 ease-in-out'>loyalty program</p> */}
                        {/* <button className='bg-secondary text-primary rounded-sm px-4 py-2 uppercase hover:bg-primary-500 transition duration-200 ease-in-out'> */}
                        <h2 className='text-sm uppercase text-primary hover:text-secondary font-extrabold transition duration-200 ease-in-out cursor-pointer' onClick={scroll}>
                            contact us</h2>

                        {/* </button> */}
                    </div>
                    <div className='md:hidden block' onClick={toggleDrawer('right', true)} >
                        <MenuOutlined></MenuOutlined>
                    </div>
                </div>

            </div >

            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
                PaperProps={{
                    sx: { width: "100%" },
                }}
                className="w-full fixed h-screen"
            >
                <div className='w-full flex justify-between bg-secondary p-5'>
                    <img src={logoDark} className="w-[80px]"></img>
                    <Close onClick={toggleDrawer('right', false)} className="text-primary"></Close>
                </div>
                <div className='flex flex-col items-center justify-center leading-9 h-full w-full bg-secondary'>
                    <Link to={'/'} onClick={toggleDrawer('right', false)}>
                        <h2 className='text-primary hover:text-[#f8f8f8] transition-all duration-200 ease-in-out'>HOME</h2>
                    </Link>
                    <Link to={'/services'} onClick={toggleDrawer('right', false)}>
                        <h2 className='text-primary uppercase hover:text-[#f8f8f8] transition-all duration-200 ease-in-out'>our services</h2>
                    </Link>
                    <Link to={'/about'} onClick={toggleDrawer('right', false)}>
                        <h2 className='text-primary uppercase hover:text-[#f8f8f8] transition-all duration-200 ease-in-out'>about us</h2>
                    </Link>
                    <div className='' onClick={toggleDrawer('right', false)}>
                        <h2 onClick={scroll} className='text-[#f8f8f8] hover:text-primary transition-all duration-200 ease-in-out uppercase'>Contact Us</h2>
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default Header