import { Link } from 'react-router-dom';
import React from 'react'
import { useState } from 'react';

const Card = ({ title, description, button_color, hover_color, border_color, link }) => {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const boldFont = {
        fontFamily: 'RNS',
    }
    const border = {
        borderColor: border_color
    }
    const background = {
        fontFamily: 'RNS',
        backgroundColor: isHovering ? hover_color : button_color
    }
    return (

        <div className='w-full h-full flex flex-col justify-between rounded-sm bg-white py-5 px-6 md:px-8'>
            <h1 className='uppercase text-primary-400 text-xl md:text-xl max-w-[165px] text-left' style={boldFont}>{title}</h1>
            <div className='my-4 border-l-4 pl-5 py-3 text-lg text-left ' style={border}>
                <p className='line-clamp-3 overflow-hidden'>
                    {description}
                </p>
            </div>
            <div className='w-full text-right'>
                <Link to={link}>
                    <button onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave} className='sm:w-full md:w-auto uppercase text-white rounded-sm text-sm py-2 px-3  transition duration-200 ease-in-out' style={background}>
                        <h1>find out more</h1>
                    </button>
                </Link>
            </div>
        </div >

    )
}

export default Card