
import React, { useEffect, useRef } from 'react'
import Card from '../components/Card'
import Footer from '../components/Footer'
import Landing from '../images/Landing.svg'
import ReactGA from 'react-ga4';
const Home = ({ contactRef }) => {
    const serviceRef = useRef(null);
    const scroll = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const colors = ['#EF476F', '#06D6A0', '#FFC300', '#4361EE', '#FF8C00'];
    const hoverColors = ['#ED315D', '#05B384', '#E0AC00', '#1F44EA', '#E07B00']
    const services = [
        {
            title: 'Web Development',
            description: 'Start building your website today and make it as beautiful as you want it to be. We can do anything from designing your site, coding it, hosting it, or even managing it. Linebits will give you a website designed with best practices in mind, and will come with full lifetime support from us to maintain it.'
        },
        {
            title: 'MOBILE APP DEVELOPMENT',
            description: 'Linebits provides solutions for all shapes and sizes. Create anything from a single view app all the way up to multi-screen apps (for both Android and iOS). Get an app developed without wasting time on back-and-forth or settling for an out-of-date template.'
        },
        {
            title: 'DESKTOP APP DEVELOPMENT',
            description: "We strive to provide innovative solutions that cater to your business needs. Our focus is on quality, usability, privacy and security. But most of all, we're an awesome team that loves making high quality desktop software for you!"
        },
        {
            title: 'USER INTERFACE DESIGN',
            description: "Our designers work painstakingly on every pixel to make sure your site is at its best. We don't want any surprises when it's time to launch your site."
        },
        {
            title: 'GRAPHIC DESIGN',
            description: 'We translate your vision into digital designs while exploring the latest creative trends and platforms. Our core values are logical approach and client satisfaction. Our promise - your brand will be uniquely one-of-a-kind.'
        },
    ]
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [])
    return (
        <div className='2xl:container mx-auto'>
            <div className='landing flex flex-col justify-between'>
                <div className='w-full py-8 md:py-10 lg:py-16 px-4 lg:px-12 flex md:flex-row justify-center '>
                    <div className='w-full md:w-auto'>
                        <div className='uppercase text-2xl lg:text-4xl xl:text-5xl text-primary'>
                            <h1 className='' >Software,</h1>
                            <h1 className='' >At your doorstep</h1>
                        </div>
                        {/* <div className='my-6 block md:hidden'>
                        <img className='mx-auto' alt='Landing Page' src={Landing}></img>
                    </div> */}

                        <div className='my-6 md:my-12'>
                            <p className='text-md max-w-[600px]'>
                                Your one-stop-shop for everything digital! Whether you're just starting out, or need some updating, LINEBITS is your go-to.</p>
                        </div>
                        <div className='flex flex-col justify-start'>
                            {/* <button onClick={() => scroll(contactRef)} className='w-full lg:w-auto bg-primary border-2 border-primary uppercase text-md   lg:text-lg py-3 px-1 rounded-sm cursor-pointer text-white  hover:border-[#2BB6A8] hover:bg-[#2BB6A8] transition-all duration-200 ease-in-out' > */}

                            <h3 className='uppercase text-secondary text-lg lg:text-2xl underline decoration-primary font-extrabold'>start your project now</h3>
                            <div className='my-4'></div>
                            {/* </button> */}
                            <button onClick={() => scroll(serviceRef)} className='w-full lg:w-auto bg-white mt-2 border-2 border-primary uppercase text-md    py-3 px-1 rounded-sm cursor-pointer text-primary hover:bg-lightGray hover:border-primary transition duration-200 ease-in-out' >
                                <h3>check out our services</h3></button>
                        </div>
                    </div>
                    {/* <div className='hidden md:block lg:hidden mx-6'></div>
                <div className='hidden md:block md:w-[450px] lg:w-[500px] xl:w-[550px]'>
                    <img alt='Landing Page' src={Landing}></img>
                </div> */}

                </div>
                <div className='bg-secondary w-full flex flex-col items-start md:items-center md:flex-row py-6 px-4 md:px-10 lg:py-12 lg:px-12 my-8 lg:my-0 justify-between'>
                    <div className='flex flex-col' >
                        <h3 className='uppercase text-[#AAAAAA] text-md lg:text-lg text-left'>Achieve more</h3>
                        <h2 className='uppercase text-primary text-xl md:text-2xl xl:text-3xl text-left' >BUILD YOUR DIGITAL PRESENCE</h2>
                    </div>
                    <div className='uppercase text-md lg:text-xl text-[#AAAAAA] mt-8 md:mt-0 text-right'>
                        <h3>
                            if your business is not on the internet, <br></br> then your business will be out of business.
                        </h3>
                    </div>
                </div>
            </div>
            <div className='w-full pt-6 px-4 md:px-10 lg:pt-16 lg:px-4 xl:px-16' ref={serviceRef}>
                <div className='flex flex-col' >
                    <h3 className='uppercase text-[#AAAAAA] text-md md:text-lg'>Our services</h3>
                    <h2 className='uppercase text-secondary text-xl md:text-2xl xl:text-3xl' >SERVICES TAILORED FOR YOUR PROJECT NEEDS</h2>
                </div>
                <div className='my-6 grid gap-2 md:gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                    {services.map((data) => {
                        const tempColors = colors.slice(0);
                        const tempHover = hoverColors.slice(0);
                        const length = colors.length;
                        const index = parseInt(Math.floor(Math.random() * length), 10);
                        const border = tempColors[index];
                        const button = tempColors[index];
                        const hover = tempHover[index];
                        colors.splice(index, 1);
                        hoverColors.splice(index, 1);
                        return <Card title={data.title} description={data.description} button_color={button} hover_color={hover} border_color={border} link={'/services'}></Card>
                    })}
                </div>
            </div>
            <div className='w-full py-3 px-4 md:px-10 lg:py-6 lg:px-4 xl:px-16'></div>
            {/* <div className='w-full py-3 px-4 md:px-10 lg:py-6 lg:px-4 xl:px-16'>
                <div className='flex flex-col' >
                    <h3 className='uppercase text-secondary text-lg md:text-xl xl:text-2xl' >Other services</h3>
                </div>
                <div className='my-2 grid gap-2 md:gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                    <div className='uppercase rounded-sm bg-white p-4 text-center text-lg text-secondary' >
                        <h2>Web & EMail hosting</h2></div>
                    <div className='uppercase rounded-sm bg-white p-4 text-center text-lg text-secondary' >
                        <h2>
                            data migration</h2></div>
                </div>
            </div> */}

            <div className='bg-secondary w-full py-6 md:py-12 px-4 md:px-10 lg:py-16 lg:px-16 my-8 lg:mt-4 lg:mb-10 text-center'>
                <h1 className='uppercase text-primary text-xl md:text-2xl xl:text-3xl' >YOUR MODERN DESTINATION FOR DIGITIZING YOUR BUSINESS</h1>
            </div>

            <div className='w-full pt-6 px-4 md:px-10 lg:pt-10 lg:px-4 xl:px-16 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 pb-20 '>
                <div className='h-full flex flex-col'>
                    <div className='flex flex-col' >
                        <h4 className='uppercase text-[#AAAAAA] text-md md:text-lg'>Why Linebits ?</h4>
                        <h2 className='uppercase text-secondary text-xl md:text-2xl xl:text-3xl' >LESS TIME WRITING A PLAN. <br></br>
                            MORE TIME BUILDING YOUR BUSINESS.</h2>
                    </div>
                    <div className='my-4 lg:my-12'></div>
                    <div className='flex flex-col my-4 lg:my-0'>
                        <h2 className='text-gray-400 uppercase text-lg lg:text-xl' >Linebits offers a wide range of software and digital services, making it the perfect one-stop shop for all your software needs.</h2>
                    </div>
                    {/* <button className='w-full lg:w-auto bg-primary border-2 border-primary uppercase text-lg   lg:text-xl py-4 px-8 rounded-sm cursor-pointer text-white hover:bg-primary hover:border-primary transition duration-200 ease-in-out hidden lg:block' >get started</button> */}
                </div>
                {/* <div className='hidden md:block mx-10'></div> */}
                <div className='flex flex-col w-full'>
                    <div className='bg-white rounded-tl-sm rounded-tr-sm  p-6 border-b-4 border-b-[#FFD166]'>
                        <h2 className='uppercase text-primary text-2xl text-left mb-2' >High Quality Software</h2>
                        <p className='text-lg text-left ml-[1px]'>Linebits provides high-quality, innovative software products and services that help businesses stay ahead of the competition.</p>
                    </div>


                    <div className='bg-white rounded-tl-sm rounded-tr-sm  p-6 border-b-4 border-b-orange-400 mt-2 lg:mt-8'>
                        <h2 className='uppercase text-primary text-2xl text-left mb-2' >Tailor made solutions</h2>
                        <p className='text-lg text-left ml-[1px]'>
                            Every project is given an individual design and development approach that is tailored to meet our clients needs. Together we are not only designing software but building a sustainable solution which will enhance any business operations while preserving its stability.</p>
                    </div>
                    <div className='bg-white rounded-tl-sm rounded-tr-sm  p-6 border-b-4 border-b-[#EF476F] my-2 lg:my-8'>
                        <h2 className='uppercase text-primary text-2xl text-left mb-2' >LOYALTY PROGRAM</h2>
                        <p className='text-lg text-left ml-[1px]'>
                            Freebies for all! Whenever you start a project with Linebits, you automatically enter our loyalty program. Say yes to Linebits, and you'll collect starting 1% on every project!</p>
                    </div>

                    <div className='bg-white rounded-tl-sm rounded-tr-sm  p-6 border-b-4 border-b-[#06D6A0]'>
                        <h2 className='uppercase text-primary text-2xl text-left mb-2' >Wide choice of plans  </h2>
                        <p className='text-lg text-left ml-[1px]'>Pockets are not meant to be empty! It is never a good idea to invest your whole fortune into building your digital presence, that's why we have a vast array of plans available. Whether you're an individual or a business, we have a plan for you. </p>


                    </div>


                </div>
                {/* <button className='w-full lg:w-auto bg-primary border-2 border-primary uppercase text-lg   lg:text-xl py-4 px-8 rounded-sm cursor-pointer text-white hover:bg-primary hover:border-primary transition duration-200 ease-in-out lg:hidden' >get started</button> */}
            </div>

        </div>
    )
}

export default Home