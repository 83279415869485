import React, { useEffect } from 'react'
import mhlh from '../images/mhlh-logo.svg'
import vacosta from '../images/vacosta-logo.svg'
import benchrest from '../images/benchrest-logo.svg'
import ice from '../images/logo-ice.svg'
import ReactGA from 'react-ga4';

const About = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [])
    return (
        <div className=''>
            <div className='w-full p-4 sm:py-4 md:py-10 lg:py-16 sm:px-4 lg:px-12'>
                {/* <p className='uppercase text-gray-400 text-center text-lg' style={boldFont}>Our services</p> */}
                <h1 className='uppercase text-center text-primary text-4xl'>About us</h1>
                <div className='my-4'></div>
                <p className='mx-auto lg:container'>Linebits was founded in 2022 with the goal of providing businesses with the best software  services. We believe that technology should make life easier, not more complicated. That's why we're committed to delivering state-of-the-art software and digital services that are easy to use and make our customers' lives simpler.

                    Our team, who is always up-to-date with the latest software developments, is passionate about helping businesses succeed and we are always available to offer our expertise and advice.
                </p>

                <div className='my-20'></div>
                <div className=''>
                    <h2 className='uppercase text-primary text-lg'>Our clients</h2>
                    <div className='my-4'></div>
                    <div className='flex flex-row justify-center flex-wrap gap-y-6'>
                        <img src={mhlh} className="w-[120px] mx-4 grayscale hover:grayscale-0 transition duration-200 ease-in-out cursor-pointer"></img>
                        <img src={ice} className="w-[120px] mx-4 grayscale hover:grayscale-0 transition duration-200 ease-in-out cursor-pointer"></img>
                        <img src={vacosta} className="w-[120px] mx-4 grayscale hover:grayscale-0 transition duration-200 ease-in-out cursor-pointer"></img>
                        <img src={benchrest} className="w-[120px] mx-4 grayscale hover:grayscale-0 transition duration-200 ease-in-out cursor-pointer" ></img>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default About