import { AlternateEmail, Instagram, Phone } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'
import logoDark from '../images/linebits-logo-dark.svg'
const Footer = ({ contactRef }) => {
    return (
        <div className='' ref={contactRef}>
            <div className=' bg-[#f0f0f0] w-full mx-auto px-2 py-6 md:py-10 md:px-8 uppercase'>
                <h3 className='text-lg md:text-xl text-secondary text-center px-2 md:px-0'>
                    If you're looking for a partner who can help you take your business to the next level, we'd love to hear from you<br></br>
                </h3>
                {/* <p className='normal-case mt-2 text-gray-600'> Schedule a meeting with our team and we will contact you.</p> */}
                <div className=' p-1 flex flex-col  justify-between rounded-sm mt-4 w-[95%] md:w-5/6 lg:w-2/4 mx-auto'>
                    {/* <input className='p-2 rounded-sm  focus:outline-none text-md' placeholder='Name'></input>
                    <div className='my-1'></div>
                    <input className='p-2 rounded-sm  focus:outline-none text-md' placeholder='Email'></input>
                    <div className='my-1'></div>
                    <textarea className='p-2 rounded-sm  focus:outline-none text-md align-middle h-full' rows={4} placeholder='Message'></textarea>
                    <div className='my-1'></div>
                    <button className='rounded-sm bg-primary hover:bg-[#2BB6A8] transition-all duration-200 ease-in-out uppercase text-white p-2 w-full md:w-2/5 lg:w-1/4 mx-auto '>
                        let's talk
                    </button> */}

                    <div className='flex md:flex-row flex-col justify-evenly normal-case mx-auto w-full mt-6 gap-y-6'>
                        <div className='flex justify-center items-center'>
                            <Instagram className='text-primary'></Instagram>
                            <div className='mx-1'></div>
                            <a target="_blank" href='https://www.instagram.com/linebits.co/'>linebits.co</a>
                        </div>
                        <div className='flex justify-center'>
                            <Phone className='text-primary'></Phone>
                            <div className='mx-1'></div>
                            <a target="_blank" href='https://wa.me/96181224324'>+961 81 224 324</a>
                        </div>
                        <div className='flex justify-center'>
                            <AlternateEmail className='text-primary'></AlternateEmail>
                            <div className='mx-1'></div>
                            <a target="_blank" href='mailto:info@linebits.co'>info@linebits.co</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-center bg-secondary'>
                <div className='bg-secondary text-center flex flex-col md:flex-row justify-center text-primary text-xs uppercase py-8 px-4 '>
                    <Link to={'/'}>
                        <h5 className='cursor-pointer hover:text-white transition-all duration-200 ease-in-out'>Home</h5>
                    </Link>
                    <Link to={'/services'}>
                        <h5 className='mx-0 my-4 md:my-0 md:mx-8 cursor-pointer hover:text-white transition-all duration-200 ease-in-out'>Our Services</h5>
                    </Link>
                    <Link to={'/about'}>
                        <h5 className='cursor-pointer hover:text-white transition-all duration-200 ease-in-out'>About Us</h5>
                    </Link>
                </div>
                <div className='pt-4 pb-6 flex flex-col items-center'>
                    <img src={logoDark} className="w-[80px]"></img>
                    <div className='my-2'></div>
                    <h2 className='uppercase text-gray-400 text-[8px]'>All rights reserved.</h2>
                </div>
            </div>
        </div>
    )
}

export default Footer